<template>
    <v-dialog v-model="show" :width="width">
        <template #activator="{on, attrs}">
            <slot name="activator" :on="on" :attrs="attrs"></slot>
        </template>
        <template #default="dialog">
            <v-card class="pa-3">
                <v-card-title class="headline">{{ message }}</v-card-title>
                <v-card-text>
                    <slot name="default"></slot>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="dialog.value = false">Annulla</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!enabled" :color="color" @click="confirmButton(dialog)">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: Boolean,
        message: String,
        item: {
            type: Object,
            required: false
        },
        color: {
            type: String,
            default: 'primary'
        },
        cancel: {
            type: String,
            default: 'Annulla'
        },
        confirm: {
            type: String,
            default: 'Conferma'
        },
        width: {
            type: String,
            default: 'unset'
        },
        enabled: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        }
    },
    methods: {
        confirmButton(dialog) {
            dialog.value = false;
            this.$emit('confirm', this.item);
        }
    }
}
</script>
