var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('router-link',{attrs:{"to":{name: 'ordinativo_checklist', params: {id: _vm.ordinativo.id}}}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" Dettaglio ")])],1),_c('Confirm',{attrs:{"message":"Aggiungi allegato","width":"400px"},on:{"confirm":_vm.uploadAllegato},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$auth(['amministratore', 'ispettore']))?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"success"}},'v-btn',attrs,false),on),[_vm._v(" Carica file ")]):_vm._e()]}},{key:"default",fn:function(){return [_c('v-file-input',{attrs:{"label":"Allegato"},model:{value:(_vm.allegato),callback:function ($$v) {_vm.allegato=$$v},expression:"allegato"}})]},proxy:true}])})],1),_c('v-data-table',{attrs:{"headers":[
            {value:'nomefile', text: 'Nome file'},
            {value:'actions', align: 'right'}],"items":_vm.allegati},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.$auth(['amministratore', 'consip', 'ispettore']))?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.download(item.id)}}},[_vm._v(" Download ")]):_vm._e(),_c('Confirm',{attrs:{"message":"Confermi la cancellazione del file?","width":"450px","item":item},on:{"confirm":_vm.cancella},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.$auth(['amministratore', 'ispettore']))?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-btn',attrs,false),on),[_vm._v(" Cancella ")]):_vm._e()]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }