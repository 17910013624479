<template>
    <v-card>
        <v-card-title>
            <router-link
                :to="{name: 'ordinativo_checklist', params: {id: ordinativo.id}}">
                <v-btn color="primary" class="mr-2">
                    Dettaglio
                </v-btn>
            </router-link>
            <Confirm message="Aggiungi allegato" width="400px" @confirm="uploadAllegato">
                <template #activator="{on, attrs}">
                    <v-btn v-if="$auth(['amministratore', 'ispettore'])" color="success" v-bind="attrs" v-on="on"
                           class="ml-auto">
                        Carica file
                    </v-btn>
                </template>
                <template #default>
                    <v-file-input v-model="allegato" label="Allegato"></v-file-input>
                </template>
            </Confirm>
        </v-card-title>
        <v-data-table
            :headers="[
                {value:'nomefile', text: 'Nome file'},
                {value:'actions', align: 'right'}]"
            :items="allegati"
        >
            <template #item.actions="{item}">
                <v-btn small color="primary" v-if="$auth(['amministratore', 'consip', 'ispettore'])"
                       @click="download(item.id)" class="mr-2">
                    Download
                </v-btn>
                <Confirm message="Confermi la cancellazione del file?" width="450px" :item="item"
                         @confirm="cancella">
                    <template #activator="{on, attrs}">
                        <v-btn v-if="$auth(['amministratore', 'ispettore'])" small color="error" v-bind="attrs"
                               v-on="on">
                            Cancella
                        </v-btn>
                    </template>
                </Confirm>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import Confirm from "@/components/Confirm";

export default {
    components: {
        Confirm,
    },
    data: () => ({
        ordinativo: {},
        allegati: [],
        allegato: null,
    }),
    mounted() {
        this.loadOrdinativo();
        this.loadAllegati();
    },
    methods: {
        async loadOrdinativo() {
            const id = this.$route.params.id;
            const res = await this.$http.get(`/audit/ordinativo/${id}/`);
            if (res.ok) {
                this.ordinativo = res.result.data;
            }
        },
        async loadAllegati() {
            const res = await this.$http.get(`/audit/ordinativo_allegato/`, {ordinativo: this.$route.params.id});
            if (res.ok) {
                this.allegati = res.result.data;
            }
        },
        async uploadAllegato(dialog) {
            const res = await this.$http.postFile(`/audit/ordinativo/allegato/${this.ordinativo.id}/upload`, this.allegato);
            if (res.ok) {
                await this.loadAllegati();
            }
            this.allegato = null;
            dialog.value = false;
        },
        async cancella(item) {
            await this.$http.delete(`/audit/ordinativo_allegato/${item.id}/`);
            await this.loadAllegati();
        },
        download(id) {
            this.$http.downloadFile(`/audit/ordinativo_allegato/${id}/download/`);
        }
    }
}
</script>
